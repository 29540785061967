var stickyHeader = true;
// var flyoutNav = false;
// var fullscreenNav = false;
// var hoverSensitiveNav = false;
// var megaMenu = false;

// var carouselBackgroundVideoYouTubeID = ''; // see https://fresco-docs.netlify.com/#/carousel/background-video

var countUpStats = true;
var countUpStatsDuration = 5000;

// var homeQuickGivingPreFooter = false;
// var transformHomepageDonationAmountImages = false;

// var feedTitleLinks = ['/first-feed-title-link', '/second-feed-title-link']; // see https://fresco-docs.netlify.com/#/home-feeds/feed-title-links
// var feedItemsCarouselBreakpoint = 576; // see https://fresco-docs.netlify.com/#/home-feeds/feed-items-carousel
// var feedItemsCarouselAutoplay = true;
// var feedItemsCarouselAutoplaySpeed = 4000;

// var subsiteHijackMainNav = ['example-subsite-name'];
// var subsiteHijackMainLogoClickDestination = ['example-subsite-name'];
// var subsiteTitleNotClickable = true;

var sidebarCards = true; // Make sure to set a card layout prefab for '.associatedRelatedPosts .associatedListing' and a card prefab to '.sidebarCard'

// Header search width same as maincalltoaction width
function resizeSearchContainer() {
  var callToActionWidth = $('.mainCallToActionButtons').css('width');
  $('.headerContent .header-search').css('width', callToActionWidth);
}
  
// Do functions on window resize, load and scroll
$(window).on("load resize scroll",function(e){
  resizeSearchContainer();
});

// Search
$('#siteSearch').focus(function(){
  $('.searchContainer').addClass('focussed');
})
$('#siteSearch').focusout(function(){
  $('.searchContainer').removeClass('focussed');
})